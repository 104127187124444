
import { defineComponent } from "vue";
import dayjs from "dayjs";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Utils from "@/utility/utils";
import { WorkCenter } from "@/types/workCenter";
import { Operation } from "@/types/state/production";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ScheduleBarOverlayForm",
  components: {
    Button,
    Calendar,
    InputText,
    Dropdown,
    Textarea,
  },
  emits: ["scheduleOrder", "saveNotes"],
  props: {
    workCenterList: {
      type: Array as () => Array<WorkCenter>,
      required: true,
      default: () => [],
    },
    workOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schedulingData: {
        startDate: "",
        endDate: "",
        workCenter: this.workCenterList[0].work_center,
        operationDescription: "",
      } as any,
      invalidFields: [] as Array<string>,
      notes: "",
    };
  },
  computed: {
    ...mapGetters({
      getCalendarTimeIncrement: "woControl/getCalendarTimeIncrement",
    }),
    estRunTime(): string {
      const diffTime = dayjs(this.schedulingData.endDate).diff(
        dayjs(this.schedulingData.startDate),
        "minutes",
      );
      if (diffTime <= 0) {
        return "0 hours";
      } else if (diffTime < 60) {
        return `${diffTime} ${diffTime === 1 ? "minute" : "minutes"}`;
      } else if (diffTime >= 60 && diffTime % 60 === 0) {
        return `${Math.floor(diffTime / 60)} ${
          Math.floor(diffTime / 60) === 1 ? "hour" : "hours"
        }`;
      } else {
        return `${Math.floor(diffTime / 60)} ${
          Math.floor(diffTime / 60) === 1 ? "hour" : "hours"
        } ${diffTime % 60} ${diffTime % 60 === 1 ? "minute" : "minutes"}`;
      }
    },
    notesChange(): boolean {
      return this.notes === this.workOrder.notes;
    },
  },
  mounted() {
    this.notes = this.workOrder.notes || "";

    const startDate = dayjs().startOf("minute");
    const startMinutes = startDate.minute();
    const roundedStartMinutes =
      Math.ceil(startMinutes / this.getCalendarTimeIncrement) *
      this.getCalendarTimeIncrement;
    const snappedStartDate = startDate.minute(roundedStartMinutes).toDate();

    this.schedulingData.startDate = snappedStartDate;

    let totalTime = 0;

    const workCenterIds = this.workCenterList.map(
      (center: WorkCenter) => center.work_center,
    );

    this.workOrder.oper_items.forEach((operation: any) => {
      if (workCenterIds.includes(operation.work_center)) {
        const newOperation = new Operation();
        newOperation.initFromOperItem(operation);
        totalTime += newOperation.totalRunTime;
      }
    });

    if (totalTime === 0) {
      totalTime = 1;
    }

    this.schedulingData.endDate = dayjs().add(totalTime, "hour").toDate();
  },
  methods: {
    dateSelected(event: any, dateType: string) {
      if (
        dayjs(this.schedulingData.endDate).isBefore(
          dayjs(this.schedulingData.startDate),
        )
      ) {
        this.invalidFields.push("endDate");
      } else {
        this.invalidFields = [];
      }
    },
    onScheduleOrder(event: any) {
      this.schedulingData.startDate = dayjs(
        this.schedulingData.startDate,
      ).format("YYYY-MM-DD HH:mm");
      this.schedulingData.endDate = dayjs(this.schedulingData.endDate).format(
        "YYYY-MM-DD HH:mm",
      );
      this.$emit("scheduleOrder", this.schedulingData);
    },
    onSaveNotes(event: any) {
      this.$emit("saveNotes", {
        workOrder: this.workOrder,
        notes: this.notes,
      });
    },
    selectedWorkCenterLabel(value: string): string {
      const workCenter = this.workCenterList.find(
        (item: any) => item.work_center === value,
      );
      this.schedulingData.operationDescription = workCenter?.description || "";
      return workCenter?.description || "";
    },
    dotColor(workOrderId: any) {
      const workCenterIndex = this.workCenterList.findIndex(
        (center) => workOrderId && center.work_center === workOrderId,
      );
      if (workCenterIndex >= 0) {
        return Utils.getColorByIndex(workCenterIndex);
      }
      return "#BBB";
    },
  },
});
